import BackButton from "../../components/BackButton/BackButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import { useAuth } from "../../features/auth/AuthContext";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import AiVoiceanimation from "../../components/AiVoiceanimation/AiVoiceanimation";
import { useDispatch } from "react-redux";
import OverviewLesson from "../../pages/home/components/dashboard/teacher/lessons/OverviewLesson";
import { useEffect, useRef, useState } from "react";
import "./LessonAndAssignmentTab.css";
import MultipleAssignment from "../MultipleAssignments/MultipleAssignment";
import { getAssignmentsByLesson, resetAssignmentsList } from "../../state/actions/getAssignmentsByLessonAction";
import { useSelector } from "react-redux";

function LessonAndAssignmentTab() {


  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth();
  const { lesson_id } = useParams();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(location?.state?.activeTab || "lessons"); // Default active tab is 'lessons'
  const [lessonParent, setlessonParent] = useState(location?.state?.lesson || {}); // Default active tab is 'lessons'
  const [courseDetails, setcourseDetails] = useState(location?.state?.course || {});
  const [rubricDetails, setrubricDetails] = useState({});
  const [assignmentCount, setassignmentCount] = useState(0)
  const [showDownloadFile, setshowDownloadFile] = useState(false);
  const modalContentRef = useRef();
  const [canDownload, setCanDownload] = useState(false);
  const [lessonFiles, setLessonFiles] = useState([]);
  const courseId = location?.state?.course?.course_id
  const [currentPage, setCurrentPage] = useState(1);

  const [isPlaying, setIsPlaying] = useState(false);


  const { assignments } = useSelector((state) => state.getassignmentsbyLesson);

  useEffect(() => {
    dispatch(getAssignmentsByLesson(lesson_id, currentPage, token));
  }, [currentPage])

  useEffect(() => {

    setassignmentCount(assignments?.length)
    const assignArr = assignments?.filter((assign) => assign.ai_feedback_json === null || assign.assignment_json
      === null)
    if (assignArr.length > 0) {
      setTimeout(() => {
        dispatch(getAssignmentsByLesson(lesson_id, currentPage, token));
      }, 30000);
    }
  }, [assignments]);

  useEffect(() => {
    return () => {
      dispatch(resetAssignmentsList());
    };
  }, []);




  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };
  const openModal = () => {
    setshowDownloadFile(true);
  };


  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    if (tabName === "Assignments") {

    }
  };


  const downloadAsPdf = async () => {
    try {
      const response = await api.post(`auth/convert-html-to-pdf/`, { html_text: lessonParent.description }, {
        responseType: 'blob',  // Important for handling binary data
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${lessonParent?.name}.pdf`); // or any other extension
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toast.success("Download successful!");
      }
    } catch (error) {
      console.error('Download error:', error);
      toast.error("Download failed.");
    }
  };

  const initiateDownload = (file) => {
    fetch(file.file_url)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.download = file.original_name;
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click'));
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch(error => console.error('Download failed:', error));
  };


  const downloadAllFiles = () => {
    if (!canDownload) {
      toast.error("Downloading is disabled for this lesson.");
      return;
    }

    if (lessonFiles.length === 0) {
      toast.error("No files to download.");
      return;
    }

    toast.info(`Starting download of ${lessonFiles.length} files...`);

    lessonFiles.forEach((file, index) => {
      setTimeout(() => {
        initiateDownload(file);
      }, index * 100);  // Small delay between each download (100 ms)
    });
  };

  return (
    <>
      <div className="lesson-andassignment-tab-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              {
                <div className="Overview-Lesson-top-header" >
                  <BackButton buttonName={lessonParent?.name} />

                  <div className="lesson-andassignment-tab-container">
                    <button
                      className={`tab-button ${activeTab === "lessons" ? "active" : ""
                        }`}
                      onClick={() => handleTabClick("lessons")}
                    >
                      Lesson
                    </button>
                    <button
                      className={`tab-button ${activeTab === "Assignments" ? "active" : ""
                        }`}
                      onClick={() => {
                        handleTabClick("Assignments");
                        setIsPlaying(false);
                      }}
                    >
                      {assignmentCount > 0 ? (assignmentCount == 1 ? `Assignment: ${assignmentCount}` : `Assignments: ${assignmentCount}`) : `Assignments: 0`}
                    </button>
                  </div>

                  {
                    (activeTab === 'lessons') && <div className="Overview-Lesson-ai-voice-animation-folder" >
                      <div className="view-course-Details-back-btn-folder">
                        {
                          lessonFiles.length > 1 ? (
                            <div className="Overview-Lesson-downlode-btn">
                              <span onClick={downloadAllFiles}>
                                <i
                                  className="fa-regular fa-file"
                                  data-tooltip-id="download-data-workboardd222"
                                ></i>
                                <Tooltip
                                  id="download-data-workboardd222"
                                  place="top"
                                  content={lessonFiles[0].original_name}
                                  type="light"
                                  effect="float"
                                />
                              </span>
                            </div>
                          ) : (canDownload &&
                            lessonFiles.map((file, index) => (
                              (
                                <div
                                  className="Overview-Lesson-downlode-btn"
                                  onClick={downloadAllFiles}
                                  key={index}
                                >
                                  <span>
                                    <i
                                      className="fa-regular fa-file"
                                      data-tooltip-id={`download-data-workboardd${index}`}
                                    ></i>
                                    <Tooltip
                                      id={`download-data-workboardd${index}`}
                                      place="top"
                                      content={file.original_name}
                                      type="light"
                                      effect="float"
                                    />
                                  </span>
                                </div>
                              )
                            ))
                          )
                        }
                      </div>
                      <div
                        className="Overview-Lesson-downlode-btn"
                        onClick={downloadAsPdf}
                      >
                        <span onClick={openModal}>
                          <i
                            className="fa-solid fa-download"
                            data-tooltip-id="download-data-workboard"
                          ></i>
                          <Tooltip
                            id="download-data-workboard"
                            place="top"
                            content="Download lesson"
                            type="light"
                            effect="float"
                          />
                        </span>
                      </div>

                      <div className="play-pause-btn-Overview-lesson-generator" onClick={togglePlay}>
                        <span>
                          <i className={isPlaying ? "fa-regular fa-circle-pause" : "fa-regular fa-circle-play"} data-tooltip-id="play-pause-tooltip"></i>
                          <Tooltip
                            id="play-pause-tooltip"
                            place="top"
                            content="Play lesson"
                            type="light"
                            effect="float"
                          />
                        </span>
                      </div>

                    </div>
                  }

                </div>
              }
            </div>
          </div>
        </div>
        <div className="tab-content">
          {activeTab === "lessons" && (
            <>
              <OverviewLesson setlessonParent={setlessonParent} setCanDownloadParent={setCanDownload} setLessonFilesParent={setLessonFiles} setrubricDetails={setrubricDetails} />
            </>
          )}
          {activeTab === "Assignments" && (
            <>
              <MultipleAssignment lesson={lessonParent} course={courseDetails} rubrics={rubricDetails} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </>
          )}
        </div>

        {activeTab === "lessons" && isPlaying && <AiVoiceanimation togglePlay={togglePlay} />}
      </div>
    </>
  );
}

export default LessonAndAssignmentTab;
