import { Grid } from "@mui/material";
import { allCountries } from "country-telephone-data";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import api from "../../../../../api/api";
import {
  calender_image,
  email_image,
  gender_image,
  grade_image,
  language_image,
  location_image,
  profile_image,
  upload_image,
  devicon_linkedin,
} from "../../../../../assets/images/images";
import EditSubject from "../../../../../components/EditSubject.js/EditSubject";
import { GOOGLE_MAP_KEY } from "../../../../../consts/constants";
import { useAuth } from "../../../../../features/auth/AuthContext";
import { updateGlobalState } from "../../../../../state/global/slice";
import { compressImage } from "../../../../../utils/CompressImage";
import { genders, titles } from "../../../../../utils/consts";
import {
  getIdsForLanguages,
  getKeyByValue
} from "../../../../../utils/utlis";
import {
  isMaxChar,
  isRequired,
  isValidMobileNumber,
} from "../../../../../utils/validator";
import CropProfileImageModal from "../../dashboard/CropProfileImageModal";

function Profile() {
  const { token } = useAuth();
  const globalState = useSelector((state) => state.globalState);
  const isTeacher = globalState.role === "Teacher";

  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };
  const dispatch = useDispatch();
  const today = new Date().toISOString().split("T")[0];
  const [isEditable, setIsEditable] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [uplodedImage, setUplodedImage] = useState({})
  const getFormattedDate = (isoDate) => {
    const dateObj = new Date(isoDate);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return dateObj.toLocaleDateString("en-US", options); // Converts to a format like "Oct 18, 2023"
  };
  const ai_avatar = isTeacher ?
    globalState.teacher_details?.data?.ai_avatar :
    globalState.student_details?.data?.student_profile_pic;
    const [AddtoLanguages, setAddtoLanguages] = useState(
      globalState.datalist?.languages ? globalState.datalist?.languages : []
    );
  // const subjects = globalState.teacher_details?.data?.subjects;
  // const interests = globalState.student_details?.data?.interests;
  useEffect(() => {
    api
      .get("/auth/get-datalist/")
      .then((response) => {
        setAddtoLanguages(response.data.languages);
        updateState("datalist", response?.data ?? {});
        // console.log('datalist 1', globalState.datalist);
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
      });
  }, []);
  const [gradedetails, setGradedetails] = useState([]);
  const subjectsKey = isTeacher ? "subject_names" : "interest_names";

  const [formState, setFormState] = useState({
    name: isTeacher
      ? globalState.teacher_details?.data?.name
      : globalState.student_details?.data?.name,
    title: isTeacher
      ? Object.keys(globalState.teacher_details?.data?.title)[0]
      : globalState.student_details?.data?.title,
    grade: isTeacher
      ? null
      : Object.keys(globalState.student_details?.data?.grade)[0],
    introduction: isTeacher
      ? globalState.teacher_details?.data?.introduction
      : globalState.student_details?.data?.introduction,
    email: isTeacher ?
      globalState.teacher_details?.data?.email :
      globalState.student_details?.data?.email,
    [subjectsKey]: isTeacher
      ? Object.values(globalState.teacher_details?.data?.subjects || {})
      : Object.values(globalState.student_details?.data?.interest || {}),
    // phone:
    //   globalState.teacher_details?.data?.phone ??
    //   globalState.student_details?.data?.phone,
    dob: isTeacher
      ? globalState.teacher_details?.data?.dob :
      globalState.student_details?.data?.dob,
    gender: Object.keys(
      isTeacher
        ? globalState.teacher_details?.data?.gender
        : globalState.student_details?.data?.gender
    )[0],
    // language_ids:
    //   isTeacher && Object.values(globalState.datalist?.languages)
    //     ? getIdsForLanguages(
    //         Object.values(globalState.teacher_details?.data?.language),
    //         globalState.datalist?.languages
    //       )
    //     : null,
    language_ids:
      isTeacher && Object.values(AddtoLanguages)
        ? getIdsForLanguages(
          Object.values(globalState.teacher_details?.data?.language),
          AddtoLanguages
        )
        : null,
    language: !isTeacher
      ? Object.values(globalState.student_details?.data?.language)?.join(",")
      : null,
    location: isTeacher ?
      globalState.teacher_details?.data?.location :
      globalState.student_details?.data?.location,
    linkedin_url: isTeacher ?
      globalState.teacher_details?.data?.linkedin_url :
      null,
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    grade: "",
    introduction: "",
    lastname: "",
    email: "",
    // phone: "",
    dob: "",
    gender: "",
    location: "",
    language: "",
    title: "",
  });

  const [selectedCountry, setSelectedCountry] = useState({
    name: "United States",
    iso2: "us",
    dialCode: globalState.teacher_details?.data?.country_code.replace(
      /\+/g,
      ""
    ),
    format: "+...-...-....",
    hasAreaCodes: false,
  });

  const handleCountryChange = (e) => {
    const country = allCountries.find((c) => c.iso2 === e.target.value);
    setSelectedCountry(country);

    setFormErrors({
      ...formErrors,
      country_code: isValidMobileNumber(
        formState.phone,
        selectedCountry.dialCode
      ),
    });
    setFormState({
      ...formState,
      country_code: `+${country.dialCode}`,
    });
  };
  function isValidDateTeacher(val) {
    let today = new Date();
    let twentyOneYearsAgo = new Date();
    twentyOneYearsAgo.setFullYear(today.getFullYear() - 21);
    twentyOneYearsAgo.setHours(0, 0, 0, 0); // Set time to the start of the day

    let selectedDate = new Date(val);
    selectedDate.setHours(0, 0, 0, 0); // Set time to the start of the day

    // Check if the selected date is after today or if it is less than 21 years ago
    if (selectedDate > today || selectedDate > twentyOneYearsAgo) {
      return false;
    }
    return true;
  }

  function isValidDateStudent(val) {
    let today = new Date();
    let twentyOneYearsAgo = new Date();
    twentyOneYearsAgo.setFullYear(today.getFullYear() - 4);
    twentyOneYearsAgo.setHours(0, 0, 0, 0); // Set time to the start of the day

    let selectedDate = new Date(val);
    selectedDate.setHours(0, 0, 0, 0); // Set time to the start of the day

    // Check if the selected date is after today or if it is less than 21 years ago
    if (selectedDate > today || selectedDate > twentyOneYearsAgo) {
      return false;
    }
    return true;
  }

  const handleDateChanged = (value) => {
    let error = "";
    error = isRequired(value);
    setFormErrors({
      ...formErrors,
      dob: error,
    });
    setFormState((prev) => ({
      ...prev,
      dob: format(new Date(value), "yyyy-MM-dd"),
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    let error = "";
    if (name === "name") {
      error = isRequired(value);
    } else if (name === "title") {
      error = value === "" || value === "0" ? "Please select title" : "";
    } else if (name === "introduction") {
      error = isRequired(value) || isMaxChar(value);
    } else if (name === "lastname") {
      error = isRequired(value);
    } else if (name === "grade") {
      error = isRequired(value);
    } else if (name === "email") {
      error = isRequired(value);
    }
    // else if (name === "phone") {
    //   error = isRequired(value);
    //   isValidMobileNumber(value, selectedCountry.dialCode);
    // }
    else if (name === "dob") {
      error = isRequired(value);
    } else if (name === "gender") {
      error = value === "" || value === "0" ? "Please select gender" : "";
    } else if (name === "location") {
      error = isRequired(value);
    } else if (name === "language") {
      error = isRequired(value);
    } else if (name === "country_code") {
      error = isValidMobileNumber(value, selectedCountry.dialCode);
    }
    setFormErrors({
      ...formErrors,
      [name]: error,
    });

    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const getUpdatedFields = () => {
    const updatedFields = Object.entries(formState).reduce(
      (result, [key, value]) => {
        const teacherValue = globalState.teacher_details?.data[key];
        const studentValue = globalState.student_details?.data[key];

        // Check if the value is not null and different from teacherValue and studentValue
        if (
          value !== teacherValue &&
          value !== studentValue &&
          value !== null
        ) {
          result[key] = value;
        }

        return result;
      },
      {}
    );

    return updatedFields;
  };
  const handleForm4Submit = async (e) => {
    await submitForm();
  };
  const submitForm = async () => {
    toast.dismiss();
    const updatedFormState = {
      ...getUpdatedFields(),
      [subjectsKey]: formState[subjectsKey]?.join(","),
    };
    if (isTeacher) {
      try {
        const response = await api.put(
          `auth/update-teacher/?teacher_id=${globalState.teacher_details?.data?.teacher_id}`,
          updatedFormState,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${token}`,
            },
          }
        );

        if (response?.data?.status === 200) {
          updateState("teacher_details", response?.data ?? {});
          setIsEditable(false);

          toast.success(
            response?.data?.message ||
            "Teacher profile has been updated successfully.",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        } else {
          setIsEditable(false);
          toast.error(response?.data?.message || "Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        setIsEditable(false);
        toast.error(
          error?.response?.data?.message ||
          error?.response?.data?.error ||
          "Something went wrong!",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
    } else {
      const newFormState = {
        ...updatedFormState,
        language: parseInt(getKeyByValue(AddtoLanguages, formState.language)),
      };
      try {
        const response = await api.put(
          "students/update-student/",
          newFormState,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${token}`,
            },
          }
        );

        if (response?.data?.status === 200) {
          updateState("student_details", response?.data ?? {});
          setIsEditable(false);

          toast.success(
            response?.data?.message ||
            "Student profile has been updated successfully.",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        } else {
          setIsEditable(false);
          toast.error(response?.data?.message || "Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        setIsEditable(false);
        toast.error(
          error?.response?.data?.message ||
          error?.response?.data?.error ||
          "Something went wrong!",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
    }
  };

  const [hasSelectedPlace, setHasSelectedPlace] = useState(globalState.teacher_details?.data?.location ?
    true : false);

  const handlePlaceSelect = (place) => {
    if (place && place.formatted_address) {
      setFormState((prev) => ({
        ...prev,
        location: place.formatted_address,
      }));
      setFormErrors({ location: "" });
      setHasSelectedPlace(true);
    } else {
      setFormErrors({ location: "Please enter a valid location" });
    }
  };

  const handleAutocompleteInputChange = (event) => {
    setFormState((prev) => ({
      ...prev,
      location: event.target.value,
    }));
    if (event.target.value === "" || !hasSelectedPlace) {
      setFormErrors({ location: "Please enter a valid location" });
    } else {
      setFormErrors({ location: "" });
    }
  };

  const handleInputBlur = () => {
    if (!hasSelectedPlace && formState.location) {
      setFormState((prev) => ({
        ...prev,
        location: "",
      }));
      setFormErrors({ location: "Please enter a valid location" });
    }
  };

  const addLanguage = (selectedLanguage) => {
    let error = "";
    error = isRequired(selectedLanguage);
    setFormErrors({
      ...formErrors,
      language_ids: error,
    });
    // add  language to formState.language string separated by comma
    setFormState((prev) => ({
      ...prev,
      language_ids: prev.language_ids
        ? prev.language_ids + "," + selectedLanguage
        : selectedLanguage,
    }));
  };
  const removeLanguage = (selectedLanguage) => {
    let error = isRequired(selectedLanguage);
    setFormErrors({
      ...formErrors,
      language_ids: error,
    });

    // Remove the selected language from formState.language string separated by a comma
    setFormState((prev) => {
      const updatedLanguageIds = prev.language_ids
        ?.split(",")
        ?.filter((item) => item !== selectedLanguage)
        .join(",");
      return {
        ...prev,
        language_ids: updatedLanguageIds,
      };
    });
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isAddToSubjetModelOpen, setIsAddToSubjetModelOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const handleFileInput = async (event) => {
    const errorKey = isTeacher ? "ai_avatar" : "student_profile_pic";
    const file = event.target.files[0];

    if (!file) {
      setSrc(null);
      return;
    }

    if (file.size > 31457280) {
      // Handle the case where the file size is too large
      toast.error("The file size exceeds the maximum limit of 1 MB");
      setFormErrors({
        ...formErrors,
        [errorKey]: "The file size exceeds the maximum limit of 1 MB",
      });
      setSrc(null);
      return; // Exit the function
    }

    const compressedImage = await compressImage(file);
    setSrc(URL.createObjectURL(compressedImage));
    onOpenModal();

    let error = "";
    error = isRequired(file);
    setFormErrors({
      ...formErrors,
      [errorKey]: error,
    });
    event.target.value = null;
  };

  const onOpenModal = () => {
    setModalIsOpen(true);
  };

  const onCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleSaveForm = () => {
    handleForm4Submit();
  };

  useEffect(() => {
    api
      .get("/auth/get-teacher/", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (response?.data) {
            updateState("teacher_details", response?.data ?? {});
          }

          // setfullteacherdetails(response.data);
          // const teacher_id = response.data?.data?.teacher_id;
          // api
          //   .get(`/courses/get-all-courses/teacher/${teacher_id}/`, {
          //     headers: {
          //       "Content-Type": "multipart/form-data",
          //       Authorization: `Token ${token}`,
          //     },
          //   })
          //   .then((response) => {
          //     if (Number(response.status) === 200) {
          //       if (response?.data) {
          //         updateState(
          //           "total_course",
          //           Number(JSON.parse(JSON.stringify(response?.data))?.count) ??
          //           0
          //         );
          //         updateState(
          //           "course_list",
          //           JSON.parse(JSON.stringify(response?.data))?.data ?? []
          //         );
          //         const totalItems =
          //           Number(JSON.parse(JSON.stringify(response?.data))?.count) ??
          //           0;
          //         const totalPages = Math.ceil(totalItems / 10);
          //         updateCoursePageTotal(totalPages);
          //       }
          //     }
          //   })
          //   .catch((error) => {
          //   });
        }
      })
      .catch((error) => {
      });
  }, [token])

  useEffect(() => {
    if (!isTeacher) {
      api
        .get("/courses/get-grade-list/", {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            //
            setGradedetails(response.data.grade);
            // setFormState((prev) => ({
            //   ...prev,
            //   grade: getKeyByDictionary(
            //     response.data.grade,
            //     globalState?.student_details?.data?.grade
            //   ),
            // }));
          }
        })
        .catch((error) => {
          //
        });
    }
    api
      .get("/auth/get-datalist/")
      .then((response) => {
        setAddtoLanguages(response.data.languages);
      })
      .catch((error) => { });
  }, [token]);
  const lastGradeUpdate = new Date(
    globalState?.student_details?.data?.grade_last_update
  );
  const currentDate = new Date();

  // Calculate the difference in time
  const timeDiff = currentDate.getTime() - lastGradeUpdate.getTime();

  // Convert time difference to days
  const daysDiff = timeDiff / (1000 * 3600 * 24);

  // Determine if the select should be read-only
  const isReadOnly = daysDiff < 14;
  return (
    <div className="account_setting_tab_sec">
      <div className="account_setting_tab_sec_head">
        <h6>Account Settings</h6>
        {isEditable ? (
          <button
            className="save_btn_set"
            onClick={handleSaveForm}
            disabled={!!formErrors.introduction}
          >
            Save
          </button>
        ) : (
          <button className="edit_btn_set" onClick={() => setIsEditable(true)}>
            <i class="fa-solid fa-pen"></i> Edit
          </button>
        )}
      </div>
      <div
        style={{
          pointerEvents: isEditable ? "auto" : "none",
        }}
      >
        <div className="about_teach_sec_new">
          <div className="about_teach_sec_new_left">
            <div className="Personalize-your-AI-Assistant-uplode-image">
              <img src={selectedImage ?? ai_avatar ?? upload_image} alt="" />
              <div
                className="profile_plus_icon"
                onChange={() => { }}
                style={{ cursor: "pointer" }}
              >
                {isEditable && (
                  <span>
                    <i class="fa-solid fa-plus"></i>
                  </span>
                )}
                {/* <img
                  src={dashboard_popup_teacher_profile_details_icon_plus_icon}
                  alt=""
                /> */}
              </div>
              <input
                type="file"
                id="choos-profile-image"
                name="student_profile_pic"
                accept="image/png, image/jpeg"
                style={{
                  height: 106,
                  width: 106,
                }}
                onChange={handleFileInput}
              />
              {/* {isEditable && (
                // <p style={{ fontSize: 10, textAlign: "center" }}>
                //   Upload an image for your AI assistant
                // </p>
              )} */}
            </div>
            <h5 style={{ textAlign: "center" }}>{formState.name}</h5>
          </div>
          <div className="about_teach_sec_new_right">
            <h4> {isTeacher ? "About Teacher" : "About Student"}</h4>
            <textarea
              name="introduction"
              style={
                formErrors.introduction ? { border: "1px solid red" } : null
              }
              value={formState.introduction || ""}
              onChange={handleInputChange}
              className="introduce-yourself-text"
              maxlength="1000"
              type="text"
              cols="20"
              rows="5"
              placeholder={`For Example:\n• Tell us about yourself.\n• What subjects do you teach?\n• What grades do you teach?\n• Do you teach at a school? If so, which one?`}
              inputprops={{
                style: { color: "red" },
              }}
            ></textarea>
            <p className="errorState">{formErrors.introduction}</p>
          </div>
        </div>
        {isTeacher &&
          <div className="profile_linkedin"
            style={{
              pointerEvents: "auto"
            }}
          >
            <div className="profile_linkedin_image">
              <img src={devicon_linkedin} />
            </div>
            {isEditable ?
              <input
                type="text"
                placeholder="linkedin"
                name="linkedin_url"
                // style={
                //   formErrors.email
                //     ? { border: "1px solid red" }
                //     : null
                // }
                value={formState.linkedin_url || ""}
                onChange={handleInputChange}
              />
              : <a href={formState.linkedin_url} target="_blank">
                {formState?.linkedin_url ?? "-"}</a>}
          </div>}
        <div className="subject_special">
          <Grid container xs={12} justifyContent={"space-between"}>
            <h5>{isTeacher ? 'Subjects' : 'Interest'}</h5>
            {isEditable && (
              <button
                className="edit_btn_set"
                onClick={() => setIsAddToSubjetModelOpen(true)}
              >
               {isTeacher ? 'Add Subject' : 'Add Interest'}
              </button>
            )}
          </Grid>
          <ul className="sub_special">
            {isTeacher
              ? formState.subject_names &&
              formState.subject_names?.map((subject) => (
                <li key={subject}>{subject}</li> // Use the subject as key if it's unique
              ))
              : formState.interest_names &&
              formState.interest_names?.map((subject) => (
                <li key={subject}>{subject}</li> // Use the subject as key if it's unique
              ))}
          </ul>
        </div>
        <div className="form_sec_settings"
        style={{
          pointerEvents: isEditable ? "auto" : "none",
        }}>
          <div
            className="dashboard-popup-teacher-profile-details-form"
            style={{
              backgroundColor: "transparent",
              width: "60%",
              padding: "0px",
            }}
          >
            <div className="dashboard-popup-teacher-profile-details-form-container">
              <div className="dashboard-popup-teacher-profile-details-input">
                <label>Name</label>
                <input
                  id="teacher-create-profile-input"
                  type="name"
                  name="name"
                  style={formErrors.name ? { border: "1px solid red" } : null}
                  value={formState.name || ""}
                  placeholder="Full name"
                  onChange={handleInputChange}
                />
                <div className="teacher-create-popup-gender-input-folder">
                  <div className="form-icon">
                    <img src={profile_image} alt="" />
                  </div>
                  <div className="setect-genter-list-dropdown">
                    {isTeacher && (
                      <select
                        name="title"
                        className="teacher-create-popup-gender-input"
                        style={
                          formErrors.title ? { border: "1px solid red" } : null
                        }
                        value={formState.title || ""}
                        onChange={handleInputChange}
                      >
                        {titles.map((title) => {
                          return (
                            <option key={title.value} value={title.value}>
                              {title.name}
                            </option>
                          );
                        })}
                      </select>
                    )}
                    {/* <div className="list-dropdown-icon">
                      <span><i class="fa-solid fa-caret-down"></i></span>
                    </div> */}
                  </div>
                </div>
                <p>{formErrors.name}</p>
              </div>
              {!isTeacher && (
                <div className="dashboard-popup-teacher-profile-details-input">
                  <label>
                    Grade
                    {/* (Update grade after 14 days since last update) */}
                  </label>
                  <select
                    name="grade"
                    style={
                      formErrors.grade
                        ? {
                          border: "1px solid red",
                          borderRadius: 6,
                        }
                        : {
                          border: "1px solid #BDBDBD",
                          borderRadius: 6,
                        }
                    }
                    value={formState.grade || ""}
                    onChange={handleInputChange}
                    readOnly={isReadOnly}
                    disabled={isReadOnly}
                  >
                    <option value="">Select Grade</option>
                    {Object.keys(gradedetails).map((key) => (
                      <option key={`gradedetails${key}`} value={key}>
                        {gradedetails[key]}
                      </option>
                    ))}
                  </select>
                  <div className="form-icon grade_icon">
                    <img src={grade_image} alt="" />
                  </div>
                  <p>{formErrors.grade}</p>
                </div>
              )}
              <div className="dashboard-popup-teacher-profile-details-input">
                <label>Email</label>
                <input
                  type="text"
                  placeholder="email id"
                  name="email"
                  readOnly
                  style={formErrors.email ? { border: "1px solid red" } : null}
                  value={formState.email || ""}
                  onChange={handleInputChange}
                />
                <div className="form-icon">
                  <img src={email_image} alt="" />
                </div>
                <p>{formErrors.email}</p>
              </div>

              {/* <div className="dashboard-popup-teacher-profile-details-input">
                <label>Mobile Number</label>
                <input
                  type="text"
                  placeholder="Mobile Number"
                  id="teacher-create-profile-input"
                  name="phone"
                  style={formErrors.phone ? { border: "1px solid red" } : null}
                  value={formState.phone || ""}
                  onChange={handleInputChange}
                />
                <div className="teacher-create-popup-gender-input-folder">
                  <div className="form-icon">
                    <img src={call_image} alt="" />
                  </div>
                  <select
                    className="teacher-create-popup-gender-input"
                    onChange={handleCountryChange}
                    value={selectedCountry.iso2}
                  >
                    {allCountries.map((country, index) => (
                      <option key={index} value={country.iso2}>
                        {selectedCountry.iso2 === country.iso2
                          ? `+${country.dialCode}`
                          : `${country.name} +${country.dialCode}`}
                      </option>
                    ))}
                  </select>
                </div>
                <p>{formErrors.phone}</p>
              </div> */}
              <div className="dashboard-popup-teacher-profile-details-input">
                <label>Birth Date</label>
                <Datetime
                  name="dob"
                  className={formErrors.dob ? "datetime-error" : "datetime"}
                  timeFormat={false}
                  inputProps={{
                    placeholder: "MM:DD:YYYY",
                    readOnly: true,
                  }}
                  max={today}
                  value={getFormattedDate(formState.dob) || ""}
                  closeOnSelect={true}
                  onChange={handleDateChanged}
                  id="Birth-Date-input"
                  dateFormat="MMM DD, YYYY"
                  isValidDate={
                    isTeacher ? isValidDateTeacher : isValidDateStudent
                  }
                />
                <div className="form-icon">
                  <img src={calender_image} alt="" />
                </div>
                <p>{formErrors.dob}</p>
              </div>
              <div className="dashboard-popup-teacher-profile-details-input">
                <label>Gender</label>
                <div className="dashboard-popup-teacher-profile-gender-folder">
                  <select
                    name="gender"
                    style={
                      formErrors.gender ? { border: "1px solid red" } : null
                    }
                    value={
                      Number.isInteger(parseInt(formState.gender))
                        ? formState.gender
                        : getKeyByValue(
                          ["Choose Other", "Male", "Female", "Other"],
                          formState.gender
                        ) || ""
                    }
                    onChange={handleInputChange}
                  >
                    {genders.map((gender) => {
                      return (
                        <option key={gender.value} value={gender.value}>
                          {gender.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-icon">
                  <img src={gender_image} alt="" />
                </div>
                <p>{formErrors.gender}</p>
              </div>
              {!isTeacher && (
                <div className="dashboard-popup-teacher-profile-details-input">
                  <label>Language</label>
                  <div className="dashboard-popup-teacher-profile-gender-folder">
                    <select
                      name="language"
                      style={
                        formErrors.language
                          ? {
                            border: "1px solid red",
                            borderRadius: 6,
                          }
                          : {
                            border: "1px solid #BDBDBD",
                            borderRadius: 6,
                          }
                      }
                      value={formState.language || ""}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Language</option>
                      {Object.keys(AddtoLanguages)
                        .map((key) => key)
                        .map((key) => (
                          <option key={key} value={AddtoLanguages[key]}>
                            {AddtoLanguages[key]}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-icon">
                    <img src={language_image} alt="" />
                  </div>
                  <p>{formErrors.language}</p>{" "}
                </div>
              )}
              <div className="dashboard-popup-teacher-profile-details-input">
                <label>Location</label>
                <Autocomplete
                  apiKey={GOOGLE_MAP_KEY}
                  onPlaceSelected={handlePlaceSelect}
                  defaultValue={formState.location}
                  onChange={handleAutocompleteInputChange}
                  onBlur={handleInputBlur}
                  options={{
                    types: ["(cities)"],
                  }}
                />
                <div className="form-icon location-icon">
                  <img src={location_image} alt="" />
                </div>
                <p>{formErrors.location}</p>
              </div>
            </div>
            {isTeacher && (
              <div className="dashboard-popup-teacher-profile-details-input location-input">
                <label>
                  Language {isEditable && <span>(Select Language)</span>}
                </label>
                <div className="dashboard-popup-teacher-profile-select-Language-container">
                  {isEditable && (
                    <div className="Subjects-you-teach-suggestions-folder">
                      {Object.keys(globalState?.datalist?.languages)
                        .filter((key) => {
                          const languageName =
                            globalState.datalist.languages[key];
                          const languageId = parseInt(key);
                          return (
                            !formState.language_ids.includes(languageName) &&
                            !formState.language_ids.includes(languageId)
                          );
                        })
                        .map((key) => (
                          <a
                            // href="#"
                            key={key}
                            onClick={() => addLanguage(key)}
                          >
                            {globalState?.datalist?.languages[key]}
                          </a>
                        ))}

                      {/* <span>More Languages coming soon</span> */}
                    </div>
                  )}
                  {globalState?.datalist?.languages &&
                    (formState.language_ids ?? "")
                      .split(",")
                      .filter((language) => language !== "").length > 0 && (
                      <div className="dashboard-popup-teacher-profile-selected-Language-folder">
                        <div className="Subjects-you-teach-feilds-folder">
                          {formState.language_ids
                            .split(",")
                            .filter((language) => language !== "")
                            .map((language) => {
                              const languageKey = String(language); // Ensure language is a string
                              return (
                                <div
                                  className="Subjects-you-teach-feilds"
                                  key={languageKey}
                                >
                                  <p>
                                    {globalState?.datalist?.languages[
                                      languageKey
                                    ] ??
                                      globalState?.datalist?.languages[
                                        getKeyByValue(
                                          globalState?.datalist?.languages,
                                          languageKey
                                        )
                                      ]}
                                  </p>
                                  {isEditable && (
                                    <a
                                      // href="#"
                                      className="subject-delete-btn"
                                      onClick={() =>
                                        removeLanguage(languageKey)
                                      }
                                    >
                                      <i className="fa-solid fa-xmark"></i>
                                    </a>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                </div>
                <p>{formErrors.language}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {modalIsOpen && (
        <CropProfileImageModal
          src={src}
          setSrc={setSrc}
          setSelectedImage={setSelectedImage}
          setImageUploaded={setImageUploaded}
          setUplodedImage={setUplodedImage}
          modalStatus={modalIsOpen}
          setModalClose={onCloseModal}
          fieldName={isTeacher ? "ai_avatar" : "student_profile_pic"}
          setFormState={setFormState}
          formState={formState}
        />
      )}
      {isAddToSubjetModelOpen && (
        <EditSubject
          subject_title="Subjects you are interested in"
          modalStatus={isAddToSubjetModelOpen}
          subjectlist={Object.values(
            isTeacher
              ? Object.values(globalState.teacher_details?.data?.subjects || {}) || {}
              : Object.values(globalState.student_details?.data?.interest || {}) || {}
          )}
          setModalClose={() => setIsAddToSubjetModelOpen(false)}
          onClose={() => setIsAddToSubjetModelOpen(false)}
          onSubmit={(subjects) => {
            setFormState((prev) => ({
              ...prev,
              [subjectsKey]: subjects,
            }));
            setIsAddToSubjetModelOpen(false);
          }}
        />
      )}
    </div>
  );
}

export default Profile;