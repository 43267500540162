import React, { useEffect, useState } from "react";
import DashboardBarGraph from "../DashboardBarGraph/DashboardBarGraph";
import "./DashboardBarFolder.css"; 
import DashboardPaechart from "../DashboardPaichart/DashboardPaichart";
import DropdownMenu from "../../../../components/DropdownMenu/DropdownMenu";
import { getAdminGraph, resetAdminGraph } from "../../../../state/actions/adminDashboardGraphAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../../features/auth/AuthContext";

const DashboardMainGraph = () => {

  const [years, setYears] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear(); // Get the current year
    const yearsArray = [];

    for (let year = 2020; year <= currentYear; year++) {
      yearsArray.push({ selectMenu: year.toString(), value: year.toString() });
    }

    setYears(yearsArray);
    setyear(currentYear);
  }, []);

    const Dropdownmenu = [
        {
          selectMenu: "Enrolled",
        value: "enroll"
        },
        {
          selectMenu: "Registered",
          value: "register"
        },
      ];

      const course_revenue_Dropdownmenu = [
        {
          selectMenu: "Monthly",
          value: "2023"
        },
        {
          selectMenu: "Yearly",
          value: "2024",
        },
      ];

      const course_revenue_Dropdownmenu2 = [
        {
          selectMenu: "JANUARY",
          value: "Jan",
        },
        {
          selectMenu: "FEBRUARY",
          value: "Feb",
        },
        {
          selectMenu: "MARCH",
          value: "Mar",
        },
        {
          selectMenu: "APRIL",
          value: "Apr",
        },
        {
          selectMenu: "MAY",
          value: "May",
        },
        {
          selectMenu: "JUNE",
          value: "Jun",
        },
        {
          selectMenu: "JULY",
          value: "Jul",
        },
        {
          selectMenu: "AUGUST",
          value: "Aug",
        },
        {
          selectMenu: "SEPTEMBER",
          value: "Sept",
        },
        {
          selectMenu: "OCTOBER",
          value: "Oct",
        },
        {
          selectMenu: "NOVEMBER",
          value: "Nov",
        },
        {
          selectMenu: "DECEMBER",
          value: "Dec",
        },
      ];

  const dispatch = useDispatch();
  const { token } = useAuth();
  const adminDashboardGraph = useSelector((state) => state.adminDashboardGraph)
  const [type, settype] = React.useState(Dropdownmenu.length > 0 ? Dropdownmenu[0].value : '');
  const [year, setyear] = React.useState("");
  const [revenueyear, setrevenueyear] = React.useState(course_revenue_Dropdownmenu.length > 0 ? course_revenue_Dropdownmenu[0].value : '');
  const [revenuemonth, setrevenuemonth] = React.useState(course_revenue_Dropdownmenu2.length > 0 ? course_revenue_Dropdownmenu2[0].value : '');

  useEffect(() => {
    if (year !== "") {
      dispatch(getAdminGraph(token, type, year))
    }
  }, [token, type, year])

  return (
    <>
      <div className="admin-dashboard-graph-section">
        <div className="admin-dashboard-graph-wrapper">
          <div className="admin-dashboard-graph-left-section">
            <div className="admin-dashboard-graph-header-wrapper">
              <p>Students {type === "enroll" ? "Enrolled" : "Registered"}</p>
                <div className="admin-dashboard-graph-dropdown">
                    <div className="admin-dashboard-graph-dropdown-menu">
                  <DropdownMenu menuItems={Dropdownmenu} dropdownValue={type} setdropdownValue={settype} />
                    </div>
                    <div className="admin-dashboard-graph-dropdown-menu">
                  <DropdownMenu menuItems={years} dropdownValue={year} setdropdownValue={setyear} />
                    </div>
                </div>
            </div>
            <DashboardBarGraph graph={adminDashboardGraph.graph} isLoading={adminDashboardGraph.isLoading} />
          </div>
          {/* <div className="admin-dashboard-graph-right-section">
            <div className="admin-dashboard-graph-header-wrapper course-revenue-dropdown-wrapper">
                <p>Course Revenue</p>
                <div className="admin-dashboard-graph-dropdown course-revenue-dropdown-folder">
                    <div className="admin-dashboard-graph-dropdown-menu">
                  <DropdownMenu menuItems={course_revenue_Dropdownmenu} dropdownValue={revenueyear} setdropdownValue={setrevenueyear} />
                    </div>
                    <div className="admin-dashboard-graph-dropdown-menu">
                  <DropdownMenu menuItems={course_revenue_Dropdownmenu2} dropdownValue={revenuemonth} setdropdownValue={setrevenuemonth} />
                    </div>
                </div>
            </div>
            <div className="DashboardPaechart">
              <DashboardPaechart/>
            </div>
            <div className="course-revenue-price-wrapper">
                <div className="course-revenue-price-"></div>
                <p>Revenue Generated : <span>1500$</span></p>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default DashboardMainGraph;
