import React, { useEffect, useState } from "react";
import api, { wsURL } from "../../../../../../api/api";
import CustomChatbot from "../../../../../../components/customChatbot/CustomChatbot";
import { useAuth } from "../../../../../../features/auth/AuthContext";

import ClickAwayListener from "@mui/material/ClickAwayListener";
const StudentDashboardChatbot = (props) => {
  const { token } = useAuth();
  const {
    ai_welcome_msg,
    ai_avatar,
    ai_name,
    teacher_id,
    lesson_name,
    isMinimize,
    chatbotStatus,
    courseId,
    lessonId,
    right,
  } = props;
  const [userId, setUserId] = useState();
  const [roomId, setRoomId] = useState();
  const [loading, setLoading] = useState(true);
  const [chatHistory, setChatHistory] = useState();
  const newWebSocketURL = `${wsURL}ws/course_chat/${roomId}/`;
  const payload = {
    student_user_id: userId,
    course_id: courseId,
    current_lesson: lesson_name,
    lesson_id: lessonId,
    assignment_id: "",
  };
  const [close, setclose] = useState(chatbotStatus === true ? true : false);
  const [isMinimized, setMinimized] = useState(isMinimize ?? false);
  const toggleclose = () => {
    props?.removeTeacherActiveChat();
  };
  const toggleMinimize = () => {
    setMinimized(!isMinimized);
  };

  const getUserId = async () => {
    try {
      const response = await api.get(`auth/get-user-id/`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        setUserId(response?.data?.user_id);
        return response?.data?.user_id;
      } else {
        return null;
      }
    } catch (error) {
      // console.log(error);
      return null;
    }
  };

  const createChatRoom = async (user_id) => {
    const payload = {
      user_id: user_id,
      against_user_id: teacher_id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    try {
      const response = await api.post(
        `ai/api/create_chat_room/`,
        payload,
        headers
      );
      if (response.status === 200 || response.status === 201) {
        setRoomId(response?.data?.room_id);
        return response?.data?.room_id;
      } else {
        return null;
      }
    } catch (error) {
      // console.log(error);
      return null;
    }
  };

  const getChatHistory = async (user_id) => {
    const payload = {
      student_user_id: user_id,
      teacher_user_id: teacher_id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    try {
      const response = await api.post(
        `ai/api/student_chat_history/`,
        payload,
        headers
      );
      if (response.status === 200 || response.status === 201) {
        const getPastHistory = response?.data?.data?.map((item) => {
          return {
            // uuid: item?.uuid,
            query: item?.query,
            answer: item?.answer.replace(/<STARTOFTURN>|<ENDOFTURN>/g, ""),
            // file: item?.file_uploads,
          };
        });
        setChatHistory(getPastHistory);
        setLoading(false);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const [ischated, setischated] = useState(true);
  useEffect(() => {
    if(ischated){
    getUserId().then((user_id) => {
      if (user_id !== null) {
        createChatRoom(user_id).then(() => {
          if (userId !== null && teacher_id !== "") {
            getChatHistory(user_id);
          }
        });
      }
    });
  }
  }, [ischated]);
  const handleInsideClick = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      {!isMinimized && close && (
        <ClickAwayListener
          onClickAway={
            chatbotStatus === true ? toggleclose : () => console.log("close")
          }
        >
          <div
            className={`studentDashboard-chatbot-container`}
            onClick={handleInsideClick}
            style={{ right: `${right + 9}%` }}
          >
            {!loading ? (
              <CustomChatbot
                roomId={roomId}
                chatHistory={chatHistory}
                socketUrl={newWebSocketURL}
                socketPayload={payload}
                ai_welcome_msg={ai_welcome_msg}
                ai_avatar={ai_avatar}
                ai_name={ai_name}
                school_name={"SRDS"}
                onClose={toggleclose}
                onMinimize={toggleMinimize}
                setischated={setischated}
              />
            ) : (
              <></>
            )}
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};

export default StudentDashboardChatbot;
