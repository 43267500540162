import Fab from "@mui/material/Fab";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import api, { wsURL } from "../../api/api";
import { getUserId } from "../../api/get_user_id";
import { studentDashboard_Small_AI_chatbot_image } from "../../assets/images/images";
import { useAuth } from "../../features/auth/AuthContext";
import CustomTooltip from "../../pages/customtooltip/CustomTooltip";
import { changeScrollPopup, useOutsideClick } from "../../utils/utlis";
import CustomChatbot from "../customChatbot/CustomChatbot";

function UniversalChatbot() {
  const { token } = useAuth();
  const globalState = useSelector((state) => state.globalState);
  const teacherData = globalState.teacher_details?.data;
  const [userId, setUserId] = useState();
  const timestamp = Date.now();
  const [loading, setLoading] = useState(true);
  const [chatHistory, setChatHistory] = useState();
  const [roomId, setRoomId] = useState();

  const payload = {
    teacher_name: teacherData?.name ?? "",
    dob: teacherData?.dob ?? "",
    ai_tone: teacherData?.ai_tone ?? "Friendly",
    subjects: teacherData?.subjects ?? "",
    location: teacherData?.location ?? "",
    speaking_language: teacherData?.language ?? "",
  };
  const [openChatbot, setOpenChatbot] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  useEffect(() => {
    getUserId(token).then((user_id) => {
      if (user_id !== null) {
        setUserId(user_id);
      }
    });
  }, []);
  const [webSocketURL, setWebSocketURL] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(true); // State to control the visibility of the tooltip
  const [ischated, setischated] = useState(true); 
  // Function to close the tooltip
  const closeTooltip = () => {
    setTooltipVisible(false);
  };
  const createChatRoom = async (user_id) => {
    const payload = {
      user_id: user_id,
      against_user_id: user_id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    try {
      const response = await api.post(
        `ai/api/create_chat_room/`,
        payload,
        headers
      );
      if (response.status === 200 || response.status === 201) {
        setRoomId(response?.data?.room_id);
        return response?.data?.room_id; // Return the room_id
      } else {
        return null;
      }
    } catch (error) {
      // console.log(error);
      return null;
    }
  };
  const getChatHistory = async (user_id) => {
    const payload = {
      teacher_user_id: user_id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    try {
      const response = await api.post(
        `ai/api/teacher_chat_history/`,
        payload,
        headers
      );
      if (response.status === 200 || response.status === 201) {
        const getPastHistory = response?.data?.data?.map((item) => {
          return {
            // uuid: item?.uuid,
            query: item?.query,
            answer: item?.answer.replace(/<STARTOFTURN>|<ENDOFTURN>/g, ""),
            // file: item?.file_uploads,
          };
        });
        // console.log("getPastHistory", response?.data?.data);
        setChatHistory(getPastHistory);
        setLoading(false);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (ischated && openChatbot && userId) {
      getChatHistory(userId);
      createChatRoom(userId).then((roomId) => {
        if (roomId && userId) {
          const newURL = `${wsURL}ws/teacher_personal_chatbot/${roomId}/?user_id=${
            userId ? userId : ""
          }`;
          setWebSocketURL(newURL);

          changeScrollPopup("hidden", "studentDashboard-chatbot-container");
        }
      });
    }
  }, [openChatbot, userId, roomId,ischated]); // Include roomId in the dependency array

  const chatbotRef = useRef(null); // Create a ref for the chatbot container
  // const getchatwindowstatus = () => {
  //   console.log("getchatwindowstatus 1" , openChatbot);
  //   if(openChatbot){
  //     setOpenChatbot(false);
  //   } else {  
  //     setOpenChatbot(true);
  //   }
  //   console.log("getchatwindowstatus" , openChatbot);
  // };
  useOutsideClick(chatbotRef, () => {
    if (openChatbot) {
      setOpenChatbot(false); // Close the chatbot when clicked outside
      changeScrollPopup("auto", "studentDashboard-chatbot-container");
    }
  });
  return (
    <>
      <Fab
        onClick={() => {
          // setOpenChatbot(!openChatbot);
          // changeScrollPopup("auto", "studentDashboard-chatbot-container");
          closeTooltip();
          getChatHistory(userId);
        }}
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          color: "common.white",
          "& img": {
            borderRadius: "100%",
          },
        }}
        size="small"
        aria-label="add"
      >
        <CustomTooltip
          title="Welcome to SRDS.ai, How may I help you?"
          isVisible={tooltipVisible}
          onClose={closeTooltip}
        >
          <img
            src={studentDashboard_Small_AI_chatbot_image}
            alt=""
            className="universal-chatbot-importantSize"
            onClick={() => {
              setOpenChatbot(!openChatbot); // Toggle the chatbot state        
              if (!openChatbot) {
                getChatHistory(userId);
                changeScrollPopup("auto", "studentDashboard-chatbot-container");
              } else {
                changeScrollPopup("hidden", "studentDashboard-chatbot-container");
              }
            }}
          />
        </CustomTooltip>
      </Fab>

      {openChatbot && userId && webSocketURL && userId !== "" ? (
        <div className="studentDashboard-chatbot-wrapper">
          <div
            className={`studentDashboard-chatbot-container`}
            ref={chatbotRef} // Apply the ref here
          >
            <CustomChatbot
              roomId={roomId}
              chatHistory={chatHistory}
              socketUrl={webSocketURL}
              socketPayload={payload}
              onClose={() => {
                setOpenChatbot(!openChatbot);
                changeScrollPopup("auto", "studentDashboard-chatbot-container");
              }}
              ai_name={"SRDS AI assistant"}
              setischated={setischated}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default UniversalChatbot;
